import React from "react";
import "../styles/contactStyles.css";
import QueryForm from "./QueryForm.jsx";
import Map from "./Map.jsx";

const Contact = () => {
  return (
    <div className="contact">
      <div className="contact-container">
        <h2>Let's Connect 👋</h2>
        <div className="contact-container-form">
          <QueryForm />
        </div>
        <h2>Locate Us</h2>
        <div className="contact-container-map">
          <Map />
        </div>
      </div>
    </div>
  );
};

export default Contact;
