import React from "react";
import "../styles/returnPolicyStyles.css";


const ReturnPol = () => {
  return (
    <div className="return-policy">
      <div className="return-container">
        <h4>Return Policy</h4>
        <h5>Responsibility and Proper Use</h5>
        <p>
          The hirer is responsible for the proper use and safe return of the
          rented equipment. While the equipment is in the hirer's custody, they
          bear full responsibility for its safety and condition.
        </p>
        <h5>Timely Return</h5>
        <p>
          If the equipment is not returned by the agreed-upon time, additional
          charges will be applied. The hirer will be liable for these additional
          fees as stipulated in the rental agreement.
        </p>
      </div>
    </div>
  );
};

export default ReturnPol;
