import React from "react";
import Navbar from "../components/Navbar.jsx";
import Hero from "../components/Hero.jsx";
import Footer from "../components/Footer.jsx";
import FeaturedProducts from "../components/FeaturedProducts.jsx";
import Clients from '../components/Clients.jsx';
import Testimonials from "../components/Testimonials.jsx";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <FeaturedProducts />
      <Clients />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default Home;
