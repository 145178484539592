export const testimonialData = [
    {
        "id": 1,
        "name": "Vinita",
        "occupation": "Filmmaker",
        "testimonial": "Great Place & Great People, Perfect Place for the Photographer & the Videographer. Very nice experience all the time. I will suggest everyone to choose this place for your support in your creative journey."

    },
    {
        "id": 2,
        "name": "Anuj Khurana",
        "occupation": "Documentary Filmmaker",
        "testimonial": "Have worked with more than 10 service providers across Delhi NCR but haven't been served so well. Very prompt service at great price."

    },
    {
        "id": 3,
        "name": "Ekta Gaur",
        "occupation": "Video Production Head",
        "testimonial": "I can say this is the best place to rent equipment and they also provided us with all the post production service as well. A well to do person and they helped us a lot in our shoot. Been with them since 6 months and hope to work lot more. Thank you Rent n Shoot."

    },
    {
        "id": 4,
        "name": "Ishita Patil",
        "occupation": "Architectural Photographer",
        "testimonial": "Rent n Shoot has been a game-changer for me. Their customer service is outstanding, and they go above and beyond to ensure we have everything we need for our shoots."

    },
    {
        "id": 5,
        "name": "Prateek Sharma",
        "occupation": "Cinematographer",
        "testimonial": "I’ve been using Rent n Shoot for all my film projects, and they never disappoint. The equipment is always well-maintained and ready to go. The staff is professional and always willing to help with any questions or concerns."

    },

]