import React from 'react';
import Navbar from "../components/Navbar.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import ProductCamera from "../components/ProductCamera.jsx";
import Footer from "../components/Footer.jsx";


const ProductsCamera = () => {
    return (
        <div>
            <Navbar />
            <SectionHeader title="Products"/>
            <ProductCamera />
            <Footer /> 
        </div>
    )
}

export default ProductsCamera;