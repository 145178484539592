import React from "react";
import { useParams } from "react-router-dom";
import "../styles/productDetailsStyles.css";
import { productsData } from "../data/productsData.js";

const ProductDetails = () => {
  let { productId } = useParams();

  // Find the product across all categories
  const product = productsData.products.reduce((foundProduct, category) => {
    // eslint-disable-next-line
    return foundProduct || category.items.find((item) => item.id == productId);
  }, null);

  // Check if the product is found
  if (!product) {
    return (
      <div className="not-found">
        <h1>Product not found</h1>
      </div>
    );
  }

  const specifications = Object.keys(product.specifications).map((key) => (
    <li key={key}>
      <strong>{key}:</strong> {product.specifications[key]}
    </li>
  ));


  //data for whatsapp button
  const phoneNumber = "919999432578";
  const message = "Hello, I am interested in your products.";
  const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div className="productDetails">
      <div className="productDetails-container">
        <div className="productImage">
          <img
            alt="Equipment"
            src={product.image}
            width="300px"
            height="auto"
          />
          <a
            href={whatsappURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              className="btn-large"
            >
              RENT NOW
            </button>
          </a>
        </div>
        <div className="productBrief">
          <h5>{product.title}</h5>
          <h6><strong>Product Category</strong> : {product.category}</h6>
          <p><strong>Product Description</strong> : {product.description}</p>
          <p>Product Specifications :</p>
          <ul>{specifications}</ul>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
