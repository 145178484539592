import React from 'react';
import Navbar from "../components/Navbar.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import ProductLens from "../components/ProductLens.jsx";
import Footer from "../components/Footer.jsx";


const ProductsLens = () => {
    return (
        <div>
            <Navbar />
            <SectionHeader title="Products"/>
            <ProductLens />
            <Footer /> 
        </div>
    )
}

export default ProductsLens;