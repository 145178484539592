import React from 'react';
import Navbar from "../components/Navbar.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import ProductDetails from "../components/ProductDetails.jsx";
import Footer from "../components/Footer.jsx";
import { productsData } from "../data/productsData.js";


const ProductsDetails = () => {


    return (
        <div>
            <Navbar />
            <SectionHeader title="Products"/>
            <ProductDetails />
            <Footer />
        </div>
    )
}


export default ProductsDetails;