export const clientsData = [
  {
    "id": 1,
    "title": "Apollo Tyres",
    "image": require("../assets/clients/apollo-tyres.png"),
  },
  {
    "id": 2,
    "title": "Ather",
    "image": require("../assets/clients/ather.png"),
  },
  {
    "id": 3,
    "title": "BMW",
    "image": require("../assets/clients/bmw.png"),
  },
  {
    "id": 4,
    "title": "Bajaj Pulsar",
    "image": require("../assets/clients/bajaj-pulsar.png"),
  },
  {
    "id": 5,
    "title": "Blackberrys",
    "image": require("../assets/clients/blackberrys.png"),
  },
  {
    "id": 6,
    "title": "Boat",
    "image": require("../assets/clients/boat.png"),
  },
  {
    "id": 7,
    "title": "Hero Motocorp",
    "image": require("../assets/clients/hero-motocorp.png"),
  },
  {
    "id": 8,
    "title": "Incredible India",
    "image": require("../assets/clients/incredible-india.png"),
  },
  {
    "id": 9,
    "title": "Linkedin",
    "image": require("../assets/clients/linkedin.png"),
  },
  {
    "id": 10,
    "title": "Mahindra Thar",
    "image": require("../assets/clients/mahindra-thar.png"),
  },
  {
    "id": 11,
    "title": "Manipal University",
    "image": require("../assets/clients/manipal-university.png"),
  },
  {
    "id": 12,
    "title": "One8",
    "image": require("../assets/clients/one8.png"),
  },
  {
    "id": 13,
    "title": "Philips",
    "image": require("../assets/clients/philips.png"),
  },
  {
    "id": 14,
    "title": "Reva University",
    "image": require("../assets/clients/reva-university.png"),
  },
  {
    "id": 15,
    "title": "Sharda University",
    "image": require("../assets/clients/sharda-university.png"),
  },
  {
    "id": 16,
    "title": "Sunburn",
    "image": require("../assets/clients/sunburn.png"),
  },
  {
    "id": 17,
    "title": "Trident Group",
    "image": require("../assets/clients/trident-group.png"),
  },
  {
    "id": 18,
    "title": "Yes Bank",
    "image": require("../assets/clients/yes-bank.png"),
  },
  {
    "id": 19,
    "title": "Zomato",
    "image": require("../assets/clients/zomato.png"),
  },
  {
    "id": 20,
    "title": "Radio Mirchi",
    "image": require("../assets/clients/radio_mirchi.png"),
  },
  {
    "id": 21,
    "title": "Physics Wallah",
    "image": require("../assets/clients/physics_wallah.png"),
  },
  {
    "id": 22,
    "title": "Pine Labs",
    "image": require("../assets/clients/pine_labs.png"),
  },
  {
    "id": 23,
    "title": "PAN Macmillan",
    "image": require("../assets/clients/pan_macmillan.png"),
  },
  {
    "id": 24,
    "title": "ISRO",
    "image": require("../assets/clients/isro_logo.png"),
  },
];
