import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import { featuredProductsData } from "../data/featuredProductsData.js";
import "../styles/featuredProductStyles.css";
import { Link } from 'react-router-dom';

const chunkArray = (array, chunkSize) => {
  const results = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    results.push(array.slice(i, i + chunkSize));
  }
  return results;
};

const FeaturedProducts = () => {
  const productChunks = chunkArray(featuredProductsData, 3);
  const productChunksMobile = chunkArray(featuredProductsData, 1);

  return (
    <div className="featured-products">
      <div className="featured-products-container">
        <h5>Featured Products</h5>
        <Carousel controls={true} indicators={false}>
          {productChunks.map((chunk, index) => (
            <Carousel.Item key={index}>
              <div className="card-group">
                {chunk.map((product) => (
                  <Card
                    key={product.id}
                    className="text-center"
                    style={{ width: "400px", height: "490px", margin: "0 2px" }}
                  >
                    <Card.Img variant="top" src={product.image} />
                    <Card.Body>
                      <Card.Title>{product.title}</Card.Title>
                     <Link to='/products'> <button className="hero-btn-small">View More</button></Link>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <div className="featured-products-container-mobile">
        <h5>Featured Products</h5>
        <Carousel controls={true} indicators={false}>
          {productChunksMobile.map((chunk, index) => (
            <Carousel.Item key={index}>
              <div className="card-group">
                {chunk.map((product) => (
                  <Card
                    key={product.id}
                    className="text-center"
                    style={{ width: "350px", height: "450px", margin: "0 5px" }}
                  >
                    <Card.Img variant="top" src={product.image} />
                    <Card.Body>
                      <Card.Title>{product.title}</Card.Title>
                      <Link to="/products"><button className="hero-btn-small">View More</button></Link>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default FeaturedProducts;
