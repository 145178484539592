import React, { useState } from "react";
import "../styles/queryFormStyles.css";
import emailjs from "@emailjs/browser";

const QueryForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [requirement, setRequirement] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your EmailJS serviceId, templateId and Public key
    const serviceId = "service_jr1y8ic";
    const templateId = "template_w83chkp";
    const PublicKey = "FYk2pZ-VtxzfFueK9";

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_email: email,
      from_number: number,
      to_name: "Rent n shoot",
      message: requirement,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, PublicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setSuccessMessage("Email sent successfully!");
        setErrorMessage("");
        setName("");
        setEmail("");
        setNumber("");
        setRequirement("");
      })
      .catch((error) => {
        console.error("Error sending email", error);
        setErrorMessage("Error sending email");
        setSuccessMessage("");
      });
  };

  return (
    <div className="emailForm-container">
      <form onSubmit={handleSubmit} className="emailForm">
        <label>Name</label>
        <input
          type="text"
          placeholder="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label>Email</label>
        <input
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>Number</label>
        <input
          type="number"
          placeholder="Your Number"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />
        <label>Requirement</label>

        <textarea
          cols="30"
          rows="10"
          placeholder="Your Requirement"
          value={requirement}
          onChange={(e) => setRequirement(e.target.value)}
        ></textarea>
        <button type="submit" className="submit-btn">
          Send
        </button>
        {/* Success Message */}
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}
        {/* Error Message */}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </form>
    </div>
  );
};

export default QueryForm;
