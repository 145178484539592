import React, { useState } from "react";
import "../styles/productCatalogLensStyles.css";
import { productsData } from "../data/productsData.js";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import { Link } from "react-router-dom";

const ProductCatalogLens = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  // Calculate the total number of pages
  const totalPages = Math.ceil(
    productsData.products[1].items.length / itemsPerPage
  );

  // Get current items based on currentPage
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = productsData.products[1].items.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Handle page change
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="productCatalogLens">
      <h5>Lens</h5>
      <div className="cardContainerLens">
        {currentItems.map((product) => (
          <Card key={product.id} className="product-card">
            <Card.Img
              variant="top"
              src={product.image}
              className="product-card-image"
            />
            <Card.Body>
              <Card.Title>{product.title}</Card.Title>
             <Link to={`/products/${product.id}`}><button className="hero-btn-small">Rent Now</button></Link>
            </Card.Body>
          </Card>
        ))}
      </div>
      <div className="product-pagination">
      <Pagination>
        <Pagination.First
          onClick={() => handlePageChange(1)}
          disabled={currentPage === 1}
        />
        <Pagination.Prev
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
        <Pagination.Last
          onClick={() => handlePageChange(totalPages)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
     </div> 
    </div>
  );
};

export default ProductCatalogLens;
