import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import ProductCatalogSound from "./ProductCatalogSound.jsx";

const ProductSound = () => {
  return (
    <div className="productCatalog">
      <div className="productCatalog-container">
        <div className="accordion">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Categories</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <Link to="/products/camera">
                    <li>Cameras</li>
                  </Link>
                  <Link to="/products/lens">
                    <li>Lens</li>
                  </Link>
                  <Link to="/products/lights">
                    <li>Lights</li>
                  </Link>
                  <Link to="/products/sound">
                    <li>Sound</li>
                  </Link>
                  <Link to="/products/monitor">
                    <li>Monitor</li>
                  </Link>
                  <Link to="/products/drone">
                    <li>Drone</li>
                  </Link>
                  <Link to="/products/accessories">
                    <li>Accessories</li>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="product-catalog">
          <ProductCatalogSound />
        </div>
      </div>
    </div>
  );
};

export default ProductSound;
