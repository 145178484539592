import React from "react";
import Navbar from "../components/Navbar.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import Footer from "../components/Footer.jsx";
import ReturnPol from "../components/ReturnPol.jsx";

const ReturnPolicy = () => {
  return (
    <div>
      <Navbar />
      <SectionHeader title="Return Policy" />
      <ReturnPol />
      <Footer />
    </div>
  );
};


export default ReturnPolicy;