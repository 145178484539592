import React from 'react';
import Navbar from "../components/Navbar.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import Footer from "../components/Footer.jsx";
import About from "../components/About.jsx";

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <SectionHeader title="About Us"/>
      <About />
      <Footer />
    </div>
  )
}

export default AboutUs
