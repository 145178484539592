import React from "react";
import Navbar from "../components/Navbar.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import Footer from "../components/Footer.jsx";
import TermsAndConditions from "../components/TermsAndConditions.jsx";

const TermsConditions = () => {
  return (
    <div>
      <Navbar />
      <SectionHeader title="Terms & Conditions"/>
      <TermsAndConditions />
      <Footer />
    </div>
  );
};

export default TermsConditions;
