import React, { useState } from "react";
import "../styles/navbarStyles.css";
import { Link } from "react-router-dom";
import { FaPhone } from "react-icons/fa6";
import { GiHamburgerMenu } from "react-icons/gi";
import Logo from "../assets/rentnshoot_logo.png";

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className="navbar">
      <div className="navbar-content">
        <div className="navbar-content-logo">
          <Link to="/">
            <img src={Logo} alt="Logo" height="auto" width={285} />
          </Link>
        </div>
        <div className="navbar-content-list">
          <ul>
            <Link to="/">
              <li>Home</li>
            </Link>
            <Link to="/products">
              <li>Products</li>
            </Link>
            <Link to="/about-us">
              <li>About Us</li>
            </Link>
            <Link to="/contact-us">
              <li>Contact Us</li>
            </Link>
          </ul>
        </div>
        <div className="navbar-button">
          <a
            href="https://rnsmedia.in"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="navbar-production-button">
              Visit RNS Media
            </button>
          </a>
        </div>
        <div className="navbar-contact">
          <a
            href="https://wa.me/919999432578"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaPhone size={22} />
            <div className="navbar-contact-details">
              Contact Us:
              <br /> <strong>9999432578</strong>
            </div>
          </a>
        </div>
        <div className="hamburger" onClick={toggleDrawer}>
          <GiHamburgerMenu size={22} />
        </div>
      </div>
      {drawerOpen && (
        <div className="side-drawer">
          <ul>
            <Link to="/" onClick={toggleDrawer}>
              <li>Home</li>
            </Link>
            <Link to="/products" onClick={toggleDrawer}>
              <li>Products</li>
            </Link>
            <Link to="/about-us" onClick={toggleDrawer}>
              <li>About Us</li>
            </Link>
            <Link to="/contact-us" onClick={toggleDrawer}>
              <li>Contact Us</li>
            </Link>
          </ul>
          <div className="side-menu">
           <a href="https://rnsmedia.in" target="_blank"><button className="hamburger-btn">Visit RNS Media</button></a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
