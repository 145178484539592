import React from "react";
import Navbar from "../components/Navbar.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import ProductMonitor from "../components/ProductMonitor.jsx";
import Footer from "../components/Footer.jsx";

const ProductsMonitor = () => {
  return (
    <div>
      <Navbar />
      <SectionHeader title="Products" />
      <ProductMonitor />
      <Footer />
    </div>
  );
};

export default ProductsMonitor;