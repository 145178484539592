import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./routes/Home.js";
import Products from "./routes/Products.js"
import AboutUs from "./routes/AboutUs.js";
import ContactUs from "./routes/ContactUs.js";
import TermsConditions from "./routes/TermsConditions.js";
import ReturnPolicy from "./routes/ReturnPolicy.js";
import DamagePolicy from "./routes/DamagePolicy.js";
import ProductsCamera from "./routes/ProductsCamera.js";
import ProductsLens from "./routes/ProductsLens.js";
import ProductsLights from "./routes/ProductsLights.js";
import ProductsMonitor from "./routes/ProductsMonitor.js";
import ProductsSound from "./routes/ProductsSound.js";
import ProductsDrone from "./routes/ProductsDrone.js";
import ProductsAccessories from "./routes/ProductsAccessories.js"
import ProductsDetails from "./routes/ProductsDetails.js";
import ScrollToTop from "./components/ScrollToTop.jsx";
import WhatsappButton from "./components/WhatsappButton.jsx";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
       <Route path="/" element={<Home/>} />
       <Route path="/products" element={<Products/>} />
       <Route path="/products/camera" element={<ProductsCamera />} />
       <Route path="/products/lens" element={<ProductsLens />} />
       <Route path="/products/lights" element={<ProductsLights />} />
       <Route path="/products/monitor" element={<ProductsMonitor />} />
       <Route path="/products/sound" element={<ProductsSound />} />
       <Route path="/products/drone" element={<ProductsDrone />} />
       <Route path="/products/accessories" element={<ProductsAccessories />} />
       <Route path="/products/:productId" element={<ProductsDetails />} />
       <Route path="/about-us" element={<AboutUs />} />
       <Route path="/contact-us" element={<ContactUs />} />
       <Route path="/terms-and-conditions" element={<TermsConditions />} />
       <Route path="/return-refund-policy" element={<ReturnPolicy />} />
       <Route path="/damage-policy" element={<DamagePolicy />} />
      </Routes>
      <WhatsappButton />
    </Router>  
    
  );
}

export default App;
