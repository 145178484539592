import React from "react";
import ProductCatalogLens from "./ProductCatalogLens.jsx";
import ProductCatalogCamera from "./ProductCatalogCamera.jsx";
import ProductCatalogDrone from "./ProductCatalogDrone.jsx";
import ProductCatalogMonitor from "./ProductCatalogMonitor.jsx";
import ProductCatalogLights from "./ProductCatalogLights.jsx";
import ProductCatalogSound from "./ProductCatalogSound.jsx";
import ProductCatalogAccessories from "./ProductCatalogAccessories.jsx";

const Catalog = () => {
  return (
    <div className="catalog">
      <ProductCatalogCamera />
      <ProductCatalogLens />
      <ProductCatalogLights />
      <ProductCatalogSound />
      <ProductCatalogMonitor />
      <ProductCatalogDrone />
      <ProductCatalogAccessories />
    </div>
  );
};

export default Catalog;
