import React from "react";
import "../styles/aboutStyles.css";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import inventory from "../assets/inventory.png";
import qa from "../assets/qa.png";
import expert from "../assets/expert.png";
import rental from "../assets/rental.png";
import founder from "../assets/founder.jpg";
import founder2 from "../assets/creative_founder.jpg";

const About = () => {
  return (
    <div className="aboutUs">
      <div className="aboutUsContainer">
        <div className="about-us">
          <h4 style={{ marginTop: "0" }}> About Us</h4>
          <p>
            Welcome to Rent n Shoot Media, where your vision meets our
            expertise. We are a premier camera rental company dedicated to
            empowering photographers, filmmakers, and content creators with
            top-of-the-line equipment and unparalleled service.
          </p>
          <h4>Our Story</h4>
          <p>
            Founded in 2017, Rent n Shoot Media began with a simple yet powerful
            mission: to make professional-grade camera equipment accessible to
            everyone. What started as a small venture with two Canon 5D Mark IV
            cameras has grown into a comprehensive rental service with an
            extensive inventory of the latest gear from industry-leading brands.
          </p>
          <h4>Our Mission</h4>
          <p>
            At Rent n Shoot Media, we believe that everyone should have the
            opportunity to tell their story through stunning visuals. Whether
            you're a seasoned professional working on a high-budget production
            or an enthusiastic amateur capturing life's precious moments, we are
            here to support your creative journey. Our mission is to provide you
            with the tools you need to bring your vision to life, without the
            burden of owning expensive equipment.
          </p>
        </div>
        <div className="about-founder">
          <h4>About the Founder</h4>
          <h6>Ranjeet Kumar Gupta : Founder</h6>
          <div className="about-founder-content">
            <div className="about-founder-picture">
              <img
                src={founder}
                alt="Founder"
                className="about-founder-content-img"
              />
            </div>
            <div className="about-founder-text">
              <p>
                Ranjeet Kumar Gupta is the visionary Founder and Business Owner
                of Rent n Shoot Media, a leading camera and equipment rental
                company. With over a decade of experience in the media industry,
                Ranjeet has established himself as a serial entrepreneur with a
                keen eye for innovation and excellence.
                <br />
                <br />
                Ranjeet's journey in the media industry began with a deep
                passion for storytelling and visual arts. Over the years, he has
                honed his skills and expertise, working on numerous projects
                that span various facets of media production. His extensive
                experience has given him a profound understanding of the
                industry's needs and challenges, inspiring him to create Rent n
                Shoot Media as a solution to bridge the gap between creativity
                and accessibility.
                <br />
                <br />
                Under Ranjeet's leadership, Rent n Shoot Media has flourished
                into a trusted name in the industry, known for its high-quality
                equipment, exceptional customer service, and commitment to
                supporting creative professionals.Ranjeet's entrepreneurial
                spirit and dedication to excellence have driven the company's
                success, making it a go-to resource for filmmakers,
                photographers, and media professionals.
                <br />
                <br />
                Ranjeet's vision for Rent n Shoot Media is rooted in the belief
                that every creative project, regardless of size or budget,
                deserves the best tools and support. His goal is to empower
                artists and storytellers by providing them with top-notch
                equipment and expert guidance, helping them bring their visions
                to life.
                <br />
                <br />
                Beyond his professional achievements, Ranjeet is a mentor and
                advocate for aspiring media professionals. He is passionate
                about sharing his knowledge and experience, inspiring the next
                generation of creatives to pursue their dreams and push the
                boundaries of what's possible.
                <br />
                <br />
                Ranjeet Kumar Gupta's unwavering dedication to the media
                industry and his commitment to fostering creativity make him a
                distinguished leader and the driving force behind Rent n Shoot
                Media. His journey is a testament to the power of passion,
                perseverance, and innovation in achieving success.
                <br />
                Thank you for getting to know Ranjeet Kumar Gupta and the story
                behind Rent n Shoot Media. We invite you to explore our services
                and join us in celebrating the art of visual storytelling.
              </p>
            </div>
          </div>
          <h6>Kumar Aditya: Creative Director and DOP</h6>
          <div className="about-founder-content">
            <div className="about-founder-picture">
              <img
                src={founder2}
                alt="Creative Director"
                className="about-founder-content-img"
              />
            </div>
            <div className="about-founder-text">
              <p>
                From amassing numerous records to attending various film award
                ceremonies, it has been a sensational journey of Kumar Aditya
                during his filmmaking career. His foray into the film industry
                has been magical and inspiring, from child prodigy to freelance
                filmmaker. From Patna's humble background, his journey to
                greatness extended to LONDON, LOS ANGELES. He recently worked in
                the film DUG DUG as a DOP. He worked as an assistant
                cinematographer for Disney Hotstar, with DOP for a film starring
                Jeremy Renner and Anil Kapoor as a Host. Before that he worked
                as a DOP for a web series with Dice Media, Season 3 for Amazon
                Mini and many others. He has been an inspiration to a lot of
                people and overcame many difficulties. He completed his
                Graduation in Mass Communication from New Delhi. He started his
                career in 2013 as a cinematographer in Mahesh Bhatt Films. After
                completing Graduation he started working as a Google Street
                Photographer. In 2014 he moved to the USA and showed his talents
                by winning several awards for his short films and documentaries.
                He always admires the low light concept of his characters and
                his major experience provides aesthetic insight on his films.
                His vision on camera, framing made him shine differently.<br />
                <br/>
                Kumar Aditya independently produced Award winning Short films like
                FREEDOM, REGRET, FESTIVAL and ADOPT. The Trailer and the
                documentary itself won many awards and has been to many film
                festivals in the US and UK. The short film also won an award in
                INDIA. He has done more than 250+ assignments which includes
                corporate films, Advertisements, Short films, Documentaries,
                Music Videos, Feature Films and major Documentaries with
                Agencies like CRPF, ITBP(Indo Tibetan Border Police), Incredible
                India And Mahindra Rally Sports. He also worked as a DOP in a
                filipino feature film Bitukang Manokand later worked in the USA
                as DOP for two short feature films. He's been a mentor in
                Filmmaking for more than a year and he holds major experience in
                Visual Communication. This young artist is now focused on making
                films based on real-life content that can support Indian culture
                in a great way. It was his dedication to camera and technology
                that made him become a cinematographer and director.
              </p>
            </div>
          </div>
        </div>
        <div className="why-us">
          <h4> Why Choose Us?</h4>
          <h6>Extensive Inventory</h6>
          <div className="why-us-section">
            <p>
              Our diverse collection of cameras, lenses, lighting, and
              accessories ensures that you have access to the right gear for any
              project. From DSLRs and mirrorless cameras to high-end cinema
              cameras and drones, we have it all.
            </p>
            <img src={inventory} alt="Inventory" height="auto" width="300px" />
          </div>
          <h6>Quality Assurance</h6>
          <div className="why-us-section">
            <p>
              Every piece of equipment undergoes rigorous testing and
              maintenance to ensure it meets our high standards of quality and
              performance. We take pride in offering gear that is reliable and
              ready to deliver outstanding results.
            </p>
            <img src={qa} alt="Inventory" height="auto" width="300px" />
          </div>
          <h6>Expert Support</h6>
          <div className="why-us-section">
            <p>
              Our team of experienced professionals is passionate about
              photography and filmmaking. We are here to provide you with expert
              advice, technical support, and personalized recommendations to
              help you make the most of our equipment.
            </p>
            <img src={expert} alt="Inventory" height="auto" width="300px" />
          </div>
          <h6>Flexible Rental Plans</h6>
          <div className="why-us-section">
            <p>
              We understand that every project is unique, which is why we offer
              flexible rental plans tailored to your needs. Whether you need
              gear for a day, a week, or a month, we have options that fit your
              schedule and budget.
            </p>
            <img src={rental} alt="Inventory" height="auto" width="300px" />
          </div>
        </div>
        <div className="community">
          <h4>Community and Commitment</h4>
          <p>
            At Rent n Shoot Media we are more than just a rental service, we are
            a community of creators. We are committed to fostering a supportive
            environment where artists can connect, collaborate, and grow.
            Through workshops, events, and our online platform, we provide
            opportunities for learning and networking.
          </p>
          <h4>Sustainability</h4>
          <p>
            We are dedicated to sustainability and responsible business
            practices. From eco-friendly packaging to initiatives that reduce
            our carbon footprint, we strive to minimize our environmental impact
            and promote a greener future.
          </p>
          <h4>Get in Touch</h4>
          <p>
            We invite you to explore our website, Social Media handles, browse
            our inventory, and discover how Rent n Shoot Media can enhance your
            next project. For any inquiries or to speak with a member of our
            team, please visit our Contact Us page or reach out via email or
            phone. We look forward to helping you create something
            extraordinary. Join us at Rent n Shoot Media, where your creativity
            is our passion. Let’s capture the world together.
          </p>
          <div className="followUs">
            <div className="social">
              <Link
                to="https://www.facebook.com/rentnshootmedia"
                target="_blank"
              >
                <FaFacebook
                  size={40}
                  style={{ color: "#000", marginRight: "1rem" }}
                />
              </Link>
              <Link to="https://www.instagram.com/rentnshoot8/" target="_blank">
                <FaInstagram
                  size={40}
                  style={{ color: "#000", margin: "0 1rem" }}
                />
              </Link>
              <Link
                to="https://www.linkedin.com/company/rent-n-shoot-media-camera-rental/"
                target="_blank"
              >
                <FaLinkedin
                  size={40}
                  style={{ color: "#000", margin: "0 1rem" }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
