import React from 'react';
import Navbar from "../components/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import Contact from "../components/Contact.jsx"
import SectionHeader from "../components/SectionHeader.jsx";

const ContactUs = () => {
  return (
    <div>
     <Navbar />
     <SectionHeader title="Contact Us"/>
     <Contact />
     <Footer />  
    </div>
  )
}

export default ContactUs
