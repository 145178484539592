import React from "react";
import Navbar from "../components/Navbar.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import Footer from "../components/Footer.jsx";
import DamagePol from "../components/DamagePol.jsx";

const DamagePolicy = () => {
  return (
    <div>
      <Navbar />
      <SectionHeader title="Damage Policy" />
      <DamagePol />
      <Footer />
    </div>
  );
};


export default DamagePolicy;