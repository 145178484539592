import React from "react";
import "../styles/termsAndConditionsStyles.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <div className="terms-container">
        <h4>Terms & Conditions</h4>
        <ul>
          <li>
            One shift is of 8 hours and it is calculated from the time of
            delivery of Equipment. In case the equipment is used for more than
            10 hours additional time will be calculated on hourly basis.
          </li>
          <li>
            The hirer will be responsible for proper use of the equipment and
            its safe return.
          </li>
          <li>
            The hirer will check/test the equipment at the time of taking the
            delivery.
          </li>
          <li>
            In case any damage is cuased to the equipment in the custody of
            hirer, the loss will be reimbursed by the hirer to the owner
          </li>
          <li>
            In case shooting is cancelled the hirer has to pay cancellation
            charges to the ownwers for a minimum of one shift. If a notice of 48
            hours is given before, nominal charges of 10% of the total amount
            will be paid to the owner
          </li>
          <li>
            50% of the total amount of the equipment is to be paid at the time
            of booking of the equipment and balance amount has to be paid at the
            time of return of the equipment
          </li>
          <li>
            One attendant of Rent n Shoot Media will accompany with the
            equipment
          </li>
          <li>
            The equipment charges are for local hiring only. In case the
            equipment is taken out of Delhi, a surcharge of 50% will be levied
            in the rates
          </li>
          <li>
            In case the equipment is requested to be sent at desired place, the
            cartage/conveyance/transportation will be borne by the hire and paid
            in cash to the attendant.
          </li>
          <li>All disputes subject to Delhi jurisdiction.</li>
        </ul>
      </div>
    </div>
  );
};

export default TermsAndConditions;
