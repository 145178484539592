import React from "react";
import "../styles/whatsappButtonStyles.css";
import whatsappLogo from "../assets/whatsapp.png";

const WhatsappButton = () => {
  return (
    <div>
      <a
        href="https://wa.me/919999432578"
        className="whatsapp-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsappLogo} alt="WhatsApp" className="whatsapp-logo" />
      </a>
    </div>
  );
};

export default WhatsappButton;
