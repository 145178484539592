import Carousel from "react-bootstrap/Carousel";
import "../styles/heroStyles.css";
import Hero2 from "../assets/hero_2.jpeg";
import Hero3 from "../assets/hero_3.jpg";
import { Link } from 'react-router-dom';

function Hero() {
  return (
    <Carousel fade controls={true} indicators={false}>
      <Carousel.Item>
        <div className="mask">
          <img src={Hero2} alt="First slide" className=" w-100" />
        </div>
        <Carousel.Caption>
          <h3>
            Affordable Rentals,{" "}
            <span style={{ color: "#fff" }}>Exceptional Results</span>
          </h3>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
          <Link to="/products"><button className="hero-btn">RENT NOW</button></Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="mask">
          <img src={Hero3} alt="First slide" className=" w-100" />
        </div>
        <Carousel.Caption>
          <h3>
            Elevate Your Shoot,{" "}
            <span style={{ color: "#9e0404" }}>Rent Today</span>
          </h3>
          {/* <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p> */}
          <Link to="/products"><button className="hero-btn">RENT NOW</button></Link>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Hero;
