import React from "react";
import "../styles/footerStyles.css";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-container-top">
          <div className="footer-info">
            <h5>GET IN TOUCH</h5>
            <div className="footer-info-content">
              <div>
                <a
                  href="https://wa.me/919999432578"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <FaPhone style={{ color: "#fff", marginRight: "10px" }} />
                  </span>{" "}
                  <span>+91-9999432578</span>
                </a>
              </div>
              <div>
                <a href="mailto:rentnshoot@gmail.com">
                  <span>
                    <MdEmail style={{ color: "#fff", marginRight: "10px" }} />
                  </span>{" "}
                  <span>rentnshoot@gmail.com</span>
                </a>
              </div>
              <div>
                <span>
                  <FaLocationDot
                    style={{ color: "#fff", marginRight: "10px" }}
                  />
                </span>{" "}
                <span>159A, Gautam Nagar Rd, Yusuf Sarai, New Delhi</span>
              </div>
            </div>
          </div>
          <div className="footer-list">
            <h5>ABOUT</h5>
            <ul>
              <Link to="/about-us">
                <li>Who We Are</li>
              </Link>
              <Link to="/contact-us">
                <li>Connect With Us</li>
              </Link>
            </ul>
          </div>
          <div className="footer-list">
            <h5>HELP</h5>
            <ul>
              <Link to="/terms-and-conditions">
                <li>Terms & Conditions</li>
              </Link>
              <Link to="/return-refund-policy">
                <li>Return Policy</li>
              </Link>
              <Link to="/damage-policy">
                <li>Damage Policy</li>
              </Link>
            </ul>
          </div>
          <div className="footer-social">
            <h5>FOLLOW US</h5>
            <div>
              <Link to="https://www.instagram.com/rentnshoot8/" target="_blank">
                <FaInstagram
                  size={20}
                  style={{ color: "#fff", marginRight: "10px" }}
                />
              </Link>{" "}
              <Link
                to="https://www.facebook.com/rentnshootmedia"
                target="_blank"
              >
                <FaFacebook
                  size={20}
                  style={{ color: "#fff", marginRight: "10px" }}
                />
              </Link>{" "}
              <Link
                to="https://www.linkedin.com/company/rent-n-shoot-media-camera-rental/"
                target="_blank"
              >
                <FaLinkedin
                  size={20}
                  style={{ color: "#fff", marginRight: "10px" }}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="footer-container-bottom">
          <p>© 2024 Rent n Shoot. All rights reserved.</p>
          <p onClick={scrollToTop} style={{cursor:'pointer'}}>SCROLL TOP ↑</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
