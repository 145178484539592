import React from "react";
import { clientsData } from "../data/clientsData.js";
import "../styles/clientsStyles.css";
import Carousel from "react-bootstrap/Carousel";

const chunkArray = (array, chunkSize) => {
  const results = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    results.push(array.slice(i, i + chunkSize));
  }
  return results;
};

const Clients = () => {
  const clientChunks = chunkArray(clientsData, 5);
  const clientChunksMobile = chunkArray(clientsData, 2)

  return (
    <div className="clients">
      <div className="clients-container">
        <h5>Our Clients</h5>
        <div className="carousel-container">
          <Carousel controls={false} indicators={false} interval={2000}>
            {clientChunks.map((chunk, index) => (
              <Carousel.Item key={index}>
                <div className="clients-group">
                  {chunk.map((client) => (
                    <img
                      key={client.id}
                      alt="Clients"
                      src={client.image}
                      className="client-logo"
                    />
                  ))}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
      <div className="clients-container-mobile">
        <h5>Our Clients</h5>
        <div className="carousel-container">
          <Carousel controls={false} indicators={false} interval={2000}>
            {clientChunksMobile.map((chunk, index) => (
              <Carousel.Item key={index}>
                <div className="clients-group">
                  {chunk.map((client) => (
                    <img
                      key={client.id}
                      alt="Clients"
                      src={client.image}
                      className="client-logo"
                    />
                  ))}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Clients;
