import React from "react";
import "../styles/damagePolStyles.css";

const DamagePol = () => {
  return (
    <div className="damage-policy">
      <div className="damage-container">
        <h4>Damage Policy</h4>
        <p>
          In the event that any damage is caused to the equipment while it is in
          the hirer's custody, the hirer will be responsible for reimbursing the
          owner for the loss. The reimbursement amount will be determined based
          on the extent of the damage and the repair or replacement cost of the
          equipment.
        </p>
      </div>
    </div>
  );
};

export default DamagePol;
