import React from "react";
import "../styles/testimonialStyles.css";
import { testimonialData } from "../data/testimonialData.js";
import Carousel from "react-bootstrap/Carousel";

const Testimonials = () => {
  return (
    <div className="testimonial">
      <div className="testimonial-container ">
        <h5>Client Testimonials</h5>
        <div className="testimonial-carousel">
          <Carousel controls={false} indicators={true} interval={5000}>
            {testimonialData.map((item) => (
              <Carousel.Item key={item.id}>
                <div className="testimonial-box">
                  <p>{item.testimonial}</p>
                  <h4>{item.name}</h4>
                  <h6>{item.occupation}</h6>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
